import s from '@/components/EnvironmentWarning/EnvironmentWarning.module.css'
import { ENV_MODALE_STORAGE_KEY } from '@/components/EnvironmentWarning/variables'
import { config } from '@/config'
import { Button, Card, Modal } from '@alma/react-components'
import React, { FC, useState } from 'react'
import { FormattedMessage } from 'react-intl'

const envsToDisplayBanner = ['sandbox', 'staging', 'dev']

export const EnvironmentModale: FC = () => {
  const [isOpen, setIsOpen] = useState(true)

  // Create a localStorage item to remember the user has seen the modal
  // The localStorage is cleared when the user logs in to make sure they can re-see the modal after a new connection.
  const onClose = () => {
    localStorage.setItem(ENV_MODALE_STORAGE_KEY, 'seen')
    setIsOpen(false)
  }
  // Do not render modal if already seen or if the environment is not in the list of environments to display the banner
  const alreadySeen = localStorage.getItem(ENV_MODALE_STORAGE_KEY) === 'seen'
  if (!envsToDisplayBanner.includes(config.ENV) || alreadySeen) return null

  return (
    <Modal isOpen={isOpen} onClose={onClose} position="center" size="lg">
      <div className={s.modalContent} data-testid="environment-modale-content">
        <div className={s.modalHeader}>
          <FormattedMessage
            defaultMessage="Demo ({env}) environment"
            id="environment.modale.warning.title"
            description="When a merchant logs in on a non-prod environment, a modal is displayed to inform the user it's a demo environment"
            values={{ env: config.ENV.toUpperCase() }}
          />
        </div>
        <Card color="yellow" className={s.modalBody} padding="lg" data-testid="paymentStatus">
          <p>
            <b>
              <FormattedMessage
                defaultMessage="🚨 The environment you are currently using is a demo environment."
                id="environment.modale.warning.content.1"
                description="When a merchant logs in on a non-prod environment, a modal is displayed to inform the user it's a demo environment."
              />
            </b>
          </p>
          <p>
            <FormattedMessage
              defaultMessage="It can be used for training or demonstrations. {br} All transactions are simulated. {br} This environment does not use real money, no payment will be processed."
              id="environment.modale.warning.content.2"
              description="When a merchant logs in on a non-prod environment, a modal is displayed to inform the user it's a demo environment."
              values={{ br: <br /> }}
            />
          </p>
          <p>
            <FormattedMessage
              defaultMessage="To use the live (production) environment and start accepting real payments, please log  in into our production environment : "
              id="environment.modale.warning.content.3"
              description="When a merchant logs in on a non-prod environment, a modal is displayed to inform the user it's a demo environment."
            />
            <a href="https://shop.getalma.eu" target="_blank" rel="noreferrer">
              shop.getalma.eu
            </a>
            .
          </p>
        </Card>
        <Button onClick={onClose} data-testid="environment-modale-close-btn">
          <FormattedMessage
            defaultMessage="I understand"
            id="environment.modale.warning.consent.btn"
            description="When a merchant logs in on a non-prod environment, a modal is displayed to inform the user it's a demo environment. This is the button to close the modale."
          />
        </Button>
      </div>
    </Modal>
  )
}
