import { searchAlmaCities } from '@/apis/geocoding'
import { CacheKeys } from '@/apis/hooks/CacheKeys'
import { useSafeQuery, UseSafeQueryOptions } from '@/apis/hooks/utils'

type AlmaCitiesSearchResult = {
  city_name: string
  department_code: string
  country_code: string
  insee_code: string
  country_name_localized: string
  region_name: string
}

export const useAlmaCitiesQuery = (
  search: string,
  options?: UseSafeQueryOptions<
    AlmaCitiesSearchResult[],
    unknown,
    AlmaCitiesSearchResult[],
    string[]
  >
) =>
  useSafeQuery(
    [CacheKeys.AlmaCities, search],
    async ({ signal }) => searchAlmaCities(search, signal),
    {
      enabled: search.length > 2,
      retry: 1,
      keepPreviousData: true,
      ...options,
    }
  )
