/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
import { useAlmaCitiesQuery } from '@/apis/hooks/queries/useAlmaCitiesQuery'
import s from '@/components/Forms/PaymentCreate/CustomerInfoForm/AddressSelector.module.css'
import {
  CustomerInfoFormValue,
  PaymentCreateFormValues,
} from '@/components/Forms/PaymentCreate/types'
import { useDisableField } from '@/components/Forms/disabledFields'
import { useThrottle } from '@/utils/throttleDebounceHooks'
import { Field, Input } from '@alma/react-components'
import cx from 'classnames'
import { useCombobox } from 'downshift'
import React, { FC, useEffect, useMemo, useRef } from 'react'
import { useFormContext, useFormState } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'

export const BirthPlaceSelector: FC = () => {
  const intl = useIntl()
  const { register, watch, setValue, trigger } = useFormContext<PaymentCreateFormValues>()
  const { errors } = useFormState<PaymentCreateFormValues>()
  const isValidAddressRef = useRef(false)
  const birthCountry = watch('customer.birthCountry')
  const addressSearch = watch('customer.birthPlaceAutocompleteFieldValue')
  const searchThrottle = useThrottle((addressSearch || '').trim())

  const registerProps = register('customer.birthPlaceAutocompleteFieldValue', {
    required: false,
    validate: () =>
      isValidAddressRef.current || !addressSearch ? true : 'This value is not valid.',
  })

  const searchResult = useAlmaCitiesQuery(searchThrottle)

  const addressSuggestions = useMemo(
    () =>
      searchResult.data
        ? searchResult.data.map<CustomerInfoFormValue['birthPlace']>(
            ({ city_name, country_code, insee_code, department_code }) => ({
              cityName: city_name,
              countryCode: country_code,
              inseeCode: insee_code,
              departmentCode: department_code,
            })
          )
        : [],
    [searchResult.data]
  )

  const {
    isOpen,
    getLabelProps,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
    setInputValue,
    closeMenu,
  } = useCombobox<CustomerInfoFormValue['birthPlace']>({
    id: 'customer.birthPlaceAutocompleteFieldValue',
    onInputValueChange(change) {
      const selectedLabel = change.selectedItem?.cityName
      isValidAddressRef.current = !!change.inputValue && change.inputValue === selectedLabel
      void trigger('customer.birthPlaceAutocompleteFieldValue')
    },
    onSelectedItemChange(change) {
      setValue('customer.birthPlace', change.selectedItem)
    },
    items: addressSuggestions,
    itemToString(item) {
      return item !== null ? item.cityName : ''
    },
  })

  useEffect(() => {
    // Sync downshift with react-hook-form when using form.reset()
    if (addressSearch === '') {
      setInputValue('')
      closeMenu()
    }
  }, [addressSearch, closeMenu, setInputValue])

  const labelProps = getLabelProps()
  const inputProps = getInputProps(registerProps)
  const menuProps = getMenuProps()
  const { disableField } = useDisableField<PaymentCreateFormValues>()

  // react-component can't put the id on the label element, so labelledby would be invalid.
  delete inputProps['aria-labelledby']
  delete menuProps['aria-labelledby']

  return (
    <Field
      label={
        <FormattedMessage
          id="customer.info.form.birthPlace"
          defaultMessage="City of birth (optional)"
          description="City of birth field label."
        />
      }
      id={labelProps.id}
      htmlFor={labelProps.htmlFor}
      error={errors.customer?.birthPlaceAutocompleteFieldValue?.message}
      className={cx({ [s.hidden]: birthCountry !== 'FR' })}
      data-testid="birth-place"
    >
      <Input
        placeholder={intl.formatMessage({
          id: 'customer.info.form.birthPlaceAutocomplete.placeholder',
          defaultMessage: 'Type to search city',
          description:
            'Placeholder within the field "City autocomplete", in the customer details section of POS page.',
        })}
        {...inputProps}
        onChangeText={() => null}
        disabled={disableField('customer.birthPlace')}
        error={!!errors.customer?.birthPlaceAutocompleteFieldValue?.message}
      />

      <div className={s.menuContainer}>
        <ul
          className={cx(s.menu, { [s.menuHidden]: !isOpen || addressSuggestions.length === 0 })}
          {...menuProps}
        >
          {isOpen &&
            addressSuggestions.map((item, index) => (
              <li
                className={cx(s.item, highlightedIndex === index && s.itemHighlighted)}
                key={index}
                {...getItemProps({
                  item,
                  index,
                })}
              >
                {item.cityName}{' '}
                {item.inseeCode && item.countryCode === 'FR' && `(${item.inseeCode})`}
              </li>
            ))}
        </ul>
      </div>
    </Field>
  )
}
