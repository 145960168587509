import { useEffect, useRef } from 'react'

export function useDateSlashesAsYouType(
  birthDate: string,
  setBirthDate: (birthDate: string) => void
) {
  const previousBirthDate = useRef('')
  const setCurrentBirthDate = useRef(setBirthDate)
  setCurrentBirthDate.current = setBirthDate

  useEffect(() => {
    if (!birthDate) {
      return
    }
    if (birthDate.length === 11) {
      setCurrentBirthDate.current(birthDate.slice(0, -1))
    }
    if (previousBirthDate.current.slice(0, -1) === birthDate) {
      // Don't add slashes if we are backspacing and remove last character if it is a slash
      if (birthDate.slice(-1) === '/') {
        setCurrentBirthDate.current(birthDate.slice(0, -1))
      }
      previousBirthDate.current = birthDate
      return
    }
    const onlyDigits = `${birthDate.replace(/\D/g, '')} `
    const withSlashes = [onlyDigits.slice(0, 2), onlyDigits.slice(2, 4), onlyDigits.slice(4)]
      .filter((part) => part.length > 0)
      .join('/')
      .trim()

    previousBirthDate.current = withSlashes
    if (birthDate !== withSlashes) {
      setCurrentBirthDate.current(withSlashes)
    }
  }, [birthDate])
}
