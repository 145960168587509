/*
 * The client's configuration is injected at runtime in the global window object
 * It is loaded there through a JS script that is linked directly in the index.html
 */

interface ClientConfig {
  API_URL: string
  WEBSITE_URL: string
  DASHBOARD_URL: string
  MAPBOX_ACCESS_TOKEN: string
  PIANO_ANALYTICS_SITE_ID: string
  LEGACY_POS_CLIENT_URL?: string
  SENTRY_DSN: string
  ADYEN_GATEWAY_WS_URL: string
  ENV: string
  DATADOG_CLIENT_TOKEN: string
  CITIES_API_ENDPOINT?: string // May be optional until browsers loads the new version.
}

declare global {
  interface Window {
    _env_: Partial<ClientConfig>
  }
}

const REQUIRED_SETTINGS: Array<keyof ClientConfig> = [
  'API_URL',
  'WEBSITE_URL',
  'DASHBOARD_URL',
  'MAPBOX_ACCESS_TOKEN',
  'PIANO_ANALYTICS_SITE_ID',
  'SENTRY_DSN',
  'ADYEN_GATEWAY_WS_URL',
  'ENV',
]

if (!window._env_) throw new Error('Could not find client configuration')

REQUIRED_SETTINGS.forEach((setting) => {
  if (!window._env_[setting]) throw new Error(`${setting} was not found in configuration`)
})

export const config = window._env_ as ClientConfig
