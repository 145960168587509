import { useFeePlansQuery, useGetPaymentQuery, useSelectMerchantMutation } from '@/apis/hooks'
import { readPaymentFormValues } from '@/apis/readPaymentFormValues'
import {
  InitialAppLoadingIndicator,
  useMe,
  useReloadAuthentication,
} from '@/components/AuthenticationManager'
import { EnvironmentModale } from '@/components/EnvironmentWarning'
import {
  isDefaultPaymentFormValues,
  useDefaultPaymentFormValues,
} from '@/components/Forms/PaymentCreate'
import { CreatePayment } from '@/components/Pages/Home/CreatePaymentPage/CreatePayment/CreatePayment'
import s from '@/components/Pages/Home/CreatePaymentPage/Home.module.css'
import { usePaymentCreationContext } from '@/components/Pages/Home/CreatePaymentPage/PaymentCreationContext/PaymentCreationContext'
import { PaymentContext } from '@/components/Pages/Home/CreatePaymentPage/PaymentCreationContext/types'
import {
  ERROR_JSON_PARSING,
  mergeFormValues,
  useValuesFromSearchParams,
} from '@/components/Pages/Home/searchParams'
import { fillFormWithSimulatedPayment } from '@/components/Pages/PaymentSimulatedPage/simulatedPayment'
import { GreyZone, List, ListItem } from '@/components/PaymentsModal/Templates'
import { useLastTerminal } from '@/components/SelectTerminal/useLastTerminal'
import { useLocale } from '@/intl/I18nContext'
import { useTracking } from '@/thirdParties/analytics'
import { sendErrorToSentry } from '@/thirdParties/sentry'
import { isStonlyLoaded, openStonlyGuidedTour } from '@/thirdParties/stonly/stonly'
import { isAtLeastRole, SimulatedPayment } from '@/types'
import { Button, Checkbox, Header, Modal } from '@alma/react-components'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useNavigationType } from 'react-router'
import { Location, useLocation, useNavigate, useSearchParams } from 'react-router-dom'

let currentKey = 0

export interface HomeState {
  fromPaymentId?: string
  simulatedPayment?: SimulatedPayment
}

export const Home: FC = () => {
  const me = useMe()
  const reloadAuthentication = useReloadAuthentication()
  const [lastTerminal] = useLastTerminal()
  const navigate = useNavigate()
  const [, setSearchParams] = useSearchParams()
  const track = useTracking('home')
  const navigationType = useNavigationType()
  const location = useLocation()

  const defaultFormValues = useDefaultPaymentFormValues()

  // load stonly Tour Guide Modal
  const canDisplayLinkToTutorial = useMemo(() => isStonlyLoaded(), [])

  // Initialize form with data from state or search params
  const { state } = useLocation() as Location & { state: HomeState | null }
  const [initialFormValues, setInitialFormValues] = useState(defaultFormValues)
  const { data: originPayment } = useGetPaymentQuery(state?.fromPaymentId)
  const { data: feePlans } = useFeePlansQuery('pos', 'all', true)
  const presetValues = useValuesFromSearchParams()
  const { setPaymentCreationContext } = usePaymentCreationContext()
  const key = useMemo(() => {
    if (navigationType !== 'POP' && location.pathname === '/' && location.search === '') {
      currentKey += 1
    }
    return currentKey
  }, [navigationType, location])

  const matchingMerchant = useMemo(
    () => me.merchants.find((merchant) => merchant.name === me.merchant.name),
    [me]
  )
  const [displayModal, setDisplayModal] = useState(matchingMerchant?.is_parent ?? false)
  const childMerchants = me.merchants.filter(
    (merchant) =>
      !merchant.is_parent && isAtLeastRole({ role_for_merchant: merchant.user_role }, 'salesperson')
  )
  const parentHasChildren = childMerchants.length > 0

  useEffect(() => {
    const shouldDisplayModal =
      (!localStorage.getItem('almaHideParentWithChildrenModal') && matchingMerchant?.is_parent) ??
      false
    setDisplayModal(shouldDisplayModal)
  }, [matchingMerchant])

  const selectMerchant = useSelectMerchantMutation({
    onMutate: () => track('switch_shop_from_parent_modal'),
    onSuccess: () => reloadAuthentication(),
  })

  const handleClose = () => {
    setDisplayModal(false)
    track('close_parent_with_children_modal')
  }

  const handleClick = (selectedMerchantId: string) => {
    selectMerchant.mutate({ merchantId: selectedMerchantId })
    setDisplayModal(false)
  }

  useEffect(() => {
    if (!isDefaultPaymentFormValues(defaultFormValues)) {
      return
    }
    if (state?.fromPaymentId && originPayment && feePlans) {
      // Payment restarted
      setInitialFormValues(
        mergeFormValues(defaultFormValues, readPaymentFormValues(originPayment, feePlans, me))
      )
      // Clear initial data from state
      navigate('/', { replace: true, state: { ...state, fromPaymentId: undefined } })
      // Set context
      setPaymentCreationContext(PaymentContext.restartedPayment)
    } else if (presetValues && presetValues !== ERROR_JSON_PARSING) {
      // Payment pre-filled from URL params
      setInitialFormValues(mergeFormValues(defaultFormValues, presetValues))
      // Clear values from URL
      setSearchParams('')
      // Set context
      setPaymentCreationContext(PaymentContext.prefilledFromParams)
    } else if (state?.simulatedPayment) {
      // Payment pre-filled from simulated payment
      setInitialFormValues(
        mergeFormValues(defaultFormValues, fillFormWithSimulatedPayment(state.simulatedPayment))
      )
      // Clear initial data from state
      navigate('/', { replace: true, state: null })
      // Set context
      setPaymentCreationContext(PaymentContext.simulatedPayment)
    }
  }, [
    state,
    originPayment,
    feePlans,
    me,
    presetValues,
    setSearchParams,
    lastTerminal,
    navigate,
    defaultFormValues,
    setPaymentCreationContext,
  ])

  // Display Stonly Modal
  const language = useLocale().locale
  const isLocaleFr = language === 'fr'

  useEffect(() => {
    if (location.search.includes('open_stonly') && isLocaleFr && canDisplayLinkToTutorial) {
      openStonlyGuidedTour('WdKOqkRIV0')
      track('stonly_open')
    }
  }, [canDisplayLinkToTutorial, isLocaleFr, location.search, track])

  // Clean localStorage for Migration Modale as it has been removed
  // This is a temporary fix to ensure a smooth migration and clean the localStorage
  // Could be removed after a few months
  useEffect(() => {
    let storageValue
    try {
      storageValue = localStorage.getItem('hasSeenMigrationModal')
      if (storageValue) {
        localStorage.removeItem('hasSeenMigrationModal')
      }
    } catch (e) {
      // Prevent crash if storage is disabled.
      sendErrorToSentry(new Error(`Reading localStorage failed in MigrationModal.`), {
        level: 'error',
        fingerprint: ['unknown-step'],
      })
    }
  }, [])

  if (!isDefaultPaymentFormValues(defaultFormValues)) {
    return <InitialAppLoadingIndicator />
  }

  return (
    <>
      <EnvironmentModale />
      <Modal
        shouldCloseOnOverlayClick={false}
        isOpen={displayModal && parentHasChildren}
        onClose={handleClose}
        size="lg"
      >
        <div className={s.greyZoneContainer}>
          <GreyZone>
            <div>
              <Header>
                <FormattedMessage
                  id="modal.error.parent.with.child.account.header"
                  defaultMessage="Are you sure you want to continue ?"
                  description="Header of the modal when a POS user is logged on the parent account saying he shouldn't do sales on this account"
                />
              </Header>
              <div className={s.parentModalSubtitle}>
                <FormattedMessage
                  id="modal.error.parent.with.child.account.subtitle"
                  defaultMessage="Warning: you are currently logged into your parent account."
                  description="Subtitle of the modal when a POS user is logged on the parent account saying he shouldn't do sales on this account"
                />
              </div>
            </div>
            <div>
              <FormattedMessage
                id="modal.error.parent.with.child.account"
                defaultMessage="We recommend that you switch to the dedicated child account before continuing with the sale to avoid unintentional errors.{newLine}"
                description="Message displayed in the modal when a POS user is logged on the parent account saying he shouldn't do sales on this account"
                values={{
                  newLine: <br />,
                }}
              />
              <div className={s.parentModalInformation}>
                <FormattedMessage
                  id="modal.error.parent.with.child.account.information"
                  defaultMessage="You can switch to one of these child accounts by clicking on one below :"
                  description="Message displayed in the modal when a POS user is logged on the parent account saying he shouldn't do sales on this account"
                />
              </div>
            </div>
            <div className={s.scrollableChildrenMerchantList}>
              <List>
                {childMerchants.map((merchant) => (
                  <ListItem key={merchant.value}>
                    <Button color="link" onClick={() => handleClick(merchant.value)}>
                      {merchant.name}
                    </Button>
                  </ListItem>
                ))}
              </List>
            </div>
          </GreyZone>
        </div>
        <div className={s.parentModalCheckbox}>
          <Checkbox
            id="checkbox.parent.with.child.hide.modal"
            label={
              <FormattedMessage
                id="checkbox.message.hide.modal"
                defaultMessage="Hide this message for next time"
                description="Checkbox that the user can check if he doesn't wand to see the message anymore."
              />
            }
            onChange={() => {
              track('not_display_parent_with_children_modal')
              localStorage.setItem('almaHideParentWithChildrenModal', 'true')
            }}
          />
        </div>
        <Button className={s.continueButton} block color="secondary" onClick={handleClose}>
          <FormattedMessage
            id="modal.error.parent.with.child.account.continue.button"
            defaultMessage="Continue"
            description="Button that allows the user to close and continue their payment."
          />
        </Button>
      </Modal>
      <CreatePayment
        key={key}
        track={track}
        initialFormValues={
          isDefaultPaymentFormValues(initialFormValues) ? initialFormValues : defaultFormValues
        }
        defaultFormValues={defaultFormValues}
      />
    </>
  )
}
