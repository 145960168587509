import { FullAddress } from '@/apis/geocoding'
import { FeePlan, Terminal } from '@/types'

export type PaymentCreateFormValues = {
  purchase: PurchaseFormValue
  customer: CustomerInfoFormValue
  order: OrderFormValue
  custom: Record<string, string | unknown>
  offerFees?: OfferFeesFormValue
  phone: PhoneNumberFormValue
  requirements: RequirementsFormValue
  link: {
    returnUrl?: string
    customerCancelUrl?: string
  }
  terminal?: {
    selected: Terminal
  }
  update?: {
    phone?: PhoneNumberFormValue
  }
}

export type PurchaseFormValue = {
  formattedAmountInEuros: string
  feePlan?: FeePlan
}

export const ADDRESS_NOT_IN_LIST_CASE = Symbol('address is not in the list')

export type CustomerInfoFormValue = {
  filledBy: 'merchant' | 'customer'
  // If filledBy customer everything else is empty
  firstName: string
  name: string
  email: string
  birthDate: string
  birthCountry: string
  birthCity: string
  birthPlace: {
    cityName: string
    countryCode: string
    departmentCode: string
    inseeCode: string
  }
  birthPlaceAutocompleteFieldValue: string
  addressAutocompleteFieldValue: string
  addressAutocomplete: FullAddress | typeof ADDRESS_NOT_IN_LIST_CASE
  addressComplement?: string
  addressManual: {
    address: string
    zipCode: string
    city: string
    country: string
    state?: string
    district?: string
  }
}

export type OrderFormValue = {
  reference: string
  comment: string
}

export type OfferFeesFormValue = {
  offered?: boolean
  offer?: boolean
}

export type PhoneNumberFormValue = {
  number: string
}

export type RequirementsFormValue = {
  mode: 'standard' | 'fast'
}
