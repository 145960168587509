/* istanbul ignore file */
import s from '@/components/EnvironmentWarning/EnvironmentWarning.module.css'
import { config } from '@/config'
import React, { FunctionComponent } from 'react'
import { FormattedMessage } from 'react-intl'

export const EnvironmentBanner: FunctionComponent = () => {
  const envsToDisplayBanner = ['sandbox', 'staging', 'local_dev', 'dev']

  if (!envsToDisplayBanner.includes(config.ENV)) return null
  return (
    <div className={s.topBanner}>
      <div className={s.leftBorder} />
      <div className={s.rightBorder} />
      <div className={s.envBadge}>
        <FormattedMessage
          id="environment.banner.demo"
          defaultMessage="DEMO"
          description="On non-prod environment a banner is displayed to inform user it is a demo environment, this is the 'demo' badge"
        />{' '}
        | {config.ENV.toUpperCase()}
      </div>
      <div className={s.infoText}>
        <FormattedMessage
          id="environment.banner.info"
          defaultMessage="You are in a test environment. All transactions are simulated."
          description="On non-prod environment a banner is displayed to inform user it is a demo environment, this is the info text"
        />
      </div>
      <div className={s.bottomBorder} />
    </div>
  )
}
