/* istanbul ignore file */
interface CountryData {
  locale: string
  countries: Record<string, string>
}

export const fr: CountryData = {
  locale: 'fr',
  countries: {
    AF: 'Afghanistan',
    AL: 'Albanie',
    DZ: 'Algérie',
    AS: 'Samoa américaines',
    AD: 'Andorre',
    AO: 'Angola',
    AI: 'Anguilla',
    AQ: 'Antarctique',
    AG: 'Antigua-et-Barbuda',
    AR: 'Argentine',
    AM: 'Arménie',
    AW: 'Aruba',
    AU: 'Australie',
    AT: 'Autriche',
    AZ: 'Azerbaïdjan',
    BS: 'Bahamas',
    BH: 'Bahreïn',
    BD: 'Bangladesh',
    BB: 'Barbade',
    BY: 'Biélorussie',
    BE: 'Belgique',
    BZ: 'Belize',
    BJ: 'Bénin',
    BM: 'Bermudes',
    BT: 'Bhoutan',
    BO: 'Bolivie',
    BA: 'Bosnie-Herzégovine',
    BW: 'Botswana',
    BV: 'Île Bouvet',
    BR: 'Brésil',
    IO: 'Océan Indien Britannique',
    BN: 'Brunei Darussalam',
    BG: 'Bulgarie',
    BF: 'Burkina Faso',
    BI: 'Burundi',
    KH: 'Cambodge',
    CM: 'Cameroun',
    CA: 'Canada',
    CV: 'Cap-Vert',
    KY: 'Îles Caïmans',
    CF: 'République Centrafricaine',
    TD: 'Tchad',
    CL: 'Chili',
    CN: 'Chine',
    CX: 'Île Christmas',
    CC: 'Îles Cocos',
    CO: 'Colombie',
    KM: 'Comores',
    CG: 'République du Congo',
    CD: 'République démocratique du Congo',
    CK: 'Îles Cook',
    CR: 'Costa Rica',
    CI: "Côte-d'Ivoire",
    HR: 'Croatie',
    CU: 'Cuba',
    CY: 'Chypre',
    CZ: 'République Tchèque',
    DK: 'Danemark',
    DJ: 'Djibouti',
    DM: 'Dominique',
    DO: 'République Dominicaine',
    EC: 'Équateur',
    EG: 'Égypte',
    SV: 'El Salvador',
    GQ: 'Guinée équatoriale',
    ER: 'Érythrée',
    EE: 'Estonie',
    ET: 'Éthiopie',
    FK: 'Îles Malouines',
    FO: 'Îles Féroé',
    FJ: 'Fidji',
    FI: 'Finlande',
    FR: 'France',
    GF: 'Guyane française',
    PF: 'Polynésie française',
    TF: 'Terres australes françaises',
    GA: 'Gabon',
    GM: 'Gambie',
    GE: 'Géorgie',
    DE: 'Allemagne',
    GH: 'Ghana',
    GI: 'Gibraltar',
    GR: 'Grèce',
    GL: 'Groenland',
    GD: 'Grenade',
    GP: 'Guadeloupe',
    GU: 'Guam',
    GT: 'Guatemala',
    GN: 'Guinée',
    GW: 'Guinée-Bissau',
    GY: 'Guyana',
    HT: 'Haïti',
    HM: 'Îles Heard-et-MacDonald',
    VA: 'Saint-Siège (Vatican)',
    HN: 'Honduras',
    HK: 'Hong Kong',
    HU: 'Hongrie',
    IS: 'Islande',
    IN: 'Inde',
    ID: 'Indonésie',
    IR: 'Iran',
    IQ: 'Irak',
    IE: 'Irlande',
    IL: 'Israël',
    IT: 'Italie',
    JM: 'Jamaïque',
    JP: 'Japon',
    JO: 'Jordanie',
    KZ: 'Kazakhstan',
    KE: 'Kenya',
    KI: 'Kiribati',
    KP: 'Corée du Nord',
    KR: 'Corée du Sud',
    KW: 'Koweït',
    KG: 'Kirghizistan',
    LA: 'Laos',
    LV: 'Lettonie',
    LB: 'Liban',
    LS: 'Lesotho',
    LR: 'Libéria',
    LY: 'Libye',
    LI: 'Liechtenstein',
    LT: 'Lituanie',
    LU: 'Luxembourg',
    MO: 'Macao',
    MK: 'Macédoine du Nord',
    MG: 'Madagascar',
    MW: 'Malawi',
    MY: 'Malaisie',
    MV: 'Maldives',
    ML: 'Mali',
    MT: 'Malte',
    MH: 'Îles Marshall',
    MQ: 'Martinique',
    MR: 'Mauritanie',
    MU: 'Maurice',
    YT: 'Mayotte',
    MX: 'Mexique',
    FM: 'Micronésie',
    MD: 'Moldavie',
    MC: 'Monaco',
    MN: 'Mongolie',
    MS: 'Montserrat',
    MA: 'Maroc',
    MZ: 'Mozambique',
    MM: 'Myanmar',
    NA: 'Namibie',
    NR: 'Nauru',
    NP: 'Népal',
    NL: 'Pays-Bas',
    NC: 'Nouvelle-Calédonie',
    NZ: 'Nouvelle-Zélande',
    NI: 'Nicaragua',
    NE: 'Niger',
    NG: 'Nigéria',
    NU: 'Niué',
    NF: 'Île Norfolk',
    MP: 'Îles Mariannes du Nord',
    NO: 'Norvège',
    OM: 'Oman',
    PK: 'Pakistan',
    PW: 'Palaos',
    PS: 'Palestine',
    PA: 'Panama',
    PG: 'Papouasie-Nouvelle-Guinée',
    PY: 'Paraguay',
    PE: 'Pérou',
    PH: 'Philippines',
    PN: 'Îles Pitcairn',
    PL: 'Pologne',
    PT: 'Portugal',
    PR: 'Porto Rico',
    QA: 'Qatar',
    RE: 'Réunion',
    RO: 'Roumanie',
    RU: 'Russie',
    RW: 'Rwanda',
    SH: 'Sainte-Hélène',
    KN: 'Saint-Christophe-et-Niévès',
    LC: 'Sainte-Lucie',
    PM: 'Saint-Pierre-et-Miquelon',
    VC: 'Saint-Vincent-et-les-Grenadines',
    WS: 'Samoa',
    SM: 'Saint-Marin',
    ST: 'São Tomé-et-Principe',
    SA: 'Arabie Saoudite',
    SN: 'Sénégal',
    SC: 'Seychelles',
    SL: 'Sierra Leone',
    SG: 'Singapour',
    SK: 'Slovaquie',
    SI: 'Slovénie',
    SB: 'Îles Salomon',
    SO: 'Somalie',
    ZA: 'Afrique du Sud',
    GS: 'Géorgie du Sud-et-les Îles Sandwich du Sud',
    ES: 'Espagne',
    LK: 'Sri Lanka',
    SD: 'Soudan',
    SR: 'Suriname',
    SJ: 'Svalbard et Île Jan Mayen',
    SZ: "Royaume d'Eswatini",
    SE: 'Suède',
    CH: 'Suisse',
    SY: 'Syrie',
    TW: 'Taïwan',
    TJ: 'Tadjikistan',
    TZ: 'République unie de Tanzanie',
    TH: 'Thaïlande',
    TL: 'Timor-Leste',
    TG: 'Togo',
    TK: 'Tokelau',
    TO: 'Tonga',
    TT: 'Trinité-et-Tobago',
    TN: 'Tunisie',
    TR: 'Turquie',
    TM: 'Turkménistan',
    TC: 'Îles Turques-et-Caïques',
    TV: 'Tuvalu',
    UG: 'Ouganda',
    UA: 'Ukraine',
    AE: 'Émirats Arabes Unis',
    GB: 'Royaume-Uni',
    US: "États-Unis d'Amérique",
    UM: 'Îles mineures éloignées des États-Unis',
    UY: 'Uruguay',
    UZ: 'Ouzbékistan',
    VU: 'Vanuatu',
    VE: 'Venezuela',
    VN: 'Vietnam',
    VG: 'Îles vierges britanniques',
    VI: 'Îles vierges américaines',
    WF: 'Wallis-et-Futuna',
    EH: 'Sahara occidental',
    YE: 'Yémen',
    ZM: 'Zambie',
    ZW: 'Zimbabwe',
    AX: 'Åland',
    BQ: 'Bonaire, Saint-Eustache et Saba',
    CW: 'Curaçao',
    GG: 'Guernesey',
    IM: 'Île de Man',
    JE: 'Jersey',
    ME: 'Monténégro',
    BL: 'Saint-Barthélemy',
    MF: 'Saint-Martin (partie française)',
    RS: 'Serbie',
    SX: 'Saint-Martin (partie néerlandaise)',
    SS: 'Soudan du Sud',
    XK: 'Kosovo',
  },
}
export const en: CountryData = {
  locale: 'en',
  countries: {
    AF: 'Afghanistan',
    AL: 'Albania',
    DZ: 'Algeria',
    AS: 'American Samoa',
    AD: 'Andorra',
    AO: 'Angola',
    AI: 'Anguilla',
    AQ: 'Antarctica',
    AG: 'Antigua and Barbuda',
    AR: 'Argentina',
    AM: 'Armenia',
    AW: 'Aruba',
    AU: 'Australia',
    AT: 'Austria',
    AZ: 'Azerbaijan',
    BS: 'Bahamas',
    BH: 'Bahrain',
    BD: 'Bangladesh',
    BB: 'Barbados',
    BY: 'Belarus',
    BE: 'Belgium',
    BZ: 'Belize',
    BJ: 'Benin',
    BM: 'Bermuda',
    BT: 'Bhutan',
    BO: 'Bolivia',
    BA: 'Bosnia and Herzegovina',
    BW: 'Botswana',
    BV: 'Bouvet Island',
    BR: 'Brazil',
    IO: 'British Indian Ocean Territory',
    BN: 'Brunei Darussalam',
    BG: 'Bulgaria',
    BF: 'Burkina Faso',
    BI: 'Burundi',
    KH: 'Cambodia',
    CM: 'Cameroon',
    CA: 'Canada',
    CV: 'Cape Verde',
    KY: 'Cayman Islands',
    CF: 'Central African Republic',
    TD: 'Chad',
    CL: 'Chile',
    CN: 'China',
    CX: 'Christmas Island',
    CC: 'Cocos (Keeling) Islands',
    CO: 'Colombia',
    KM: 'Comoros',
    CG: 'Congo',
    CD: 'Democratic Republic of the Congo',
    CK: 'Cook Islands',
    CR: 'Costa Rica',
    CI: 'Ivory Coast',
    HR: 'Croatia',
    CU: 'Cuba',
    CY: 'Cyprus',
    CZ: 'Czech Republic',
    DK: 'Denmark',
    DJ: 'Djibouti',
    DM: 'Dominica',
    DO: 'Dominican Republic',
    EC: 'Ecuador',
    EG: 'Egypt',
    SV: 'El Salvador',
    GQ: 'Equatorial Guinea',
    ER: 'Eritrea',
    EE: 'Estonia',
    ET: 'Ethiopia',
    FK: 'Falkland Islands (Malvinas)',
    FO: 'Faroe Islands',
    FJ: 'Fiji',
    FI: 'Finland',
    FR: 'France',
    GF: 'French Guiana',
    PF: 'French Polynesia',
    TF: 'French Southern Territories',
    GA: 'Gabon',
    GM: 'Gambia',
    GE: 'Georgia',
    DE: 'Germany',
    GH: 'Ghana',
    GI: 'Gibraltar',
    GR: 'Greece',
    GL: 'Greenland',
    GD: 'Grenada',
    GP: 'Guadeloupe',
    GU: 'Guam',
    GT: 'Guatemala',
    GN: 'Guinea',
    GW: 'Guinea-Bissau',
    GY: 'Guyana',
    HT: 'Haiti',
    HM: 'Heard Island and McDonald Islands',
    VA: 'Holy See (Vatican City State)',
    HN: 'Honduras',
    HK: 'Hong Kong',
    HU: 'Hungary',
    IS: 'Iceland',
    IN: 'India',
    ID: 'Indonesia',
    IR: 'Iran',
    IQ: 'Iraq',
    IE: 'Ireland',
    IL: 'Israel',
    IT: 'Italy',
    JM: 'Jamaica',
    JP: 'Japan',
    JO: 'Jordan',
    KZ: 'Kazakhstan',
    KE: 'Kenya',
    KI: 'Kiribati',
    KP: 'North Korea',
    KR: 'South Korea',
    KW: 'Kuwait',
    KG: 'Kyrgyzstan',
    LA: "Lao People's Democratic Republic",
    LV: 'Latvia',
    LB: 'Lebanon',
    LS: 'Lesotho',
    LR: 'Liberia',
    LY: 'Libya',
    LI: 'Liechtenstein',
    LT: 'Lithuania',
    LU: 'Luxembourg',
    MO: 'Macao',
    MG: 'Madagascar',
    MW: 'Malawi',
    MY: 'Malaysia',
    MV: 'Maldives',
    ML: 'Mali',
    MT: 'Malta',
    MH: 'Marshall Islands',
    MQ: 'Martinique',
    MR: 'Mauritania',
    MU: 'Mauritius',
    YT: 'Mayotte',
    MX: 'Mexico',
    FM: 'Micronesia, Federated States of',
    MD: 'Moldova, Republic of',
    MC: 'Monaco',
    MN: 'Mongolia',
    MS: 'Montserrat',
    MA: 'Morocco',
    MZ: 'Mozambique',
    MM: 'Myanmar',
    NA: 'Namibia',
    NR: 'Nauru',
    NP: 'Nepal',
    NL: 'Netherlands',
    NC: 'New Caledonia',
    NZ: 'New Zealand',
    NI: 'Nicaragua',
    NE: 'Niger',
    NG: 'Nigeria',
    NU: 'Niue',
    NF: 'Norfolk Island',
    MK: 'North Macedonia',
    MP: 'Northern Mariana Islands',
    NO: 'Norway',
    OM: 'Oman',
    PK: 'Pakistan',
    PW: 'Palau',
    PS: 'Palestine',
    PA: 'Panama',
    PG: 'Papua New Guinea',
    PY: 'Paraguay',
    PE: 'Peru',
    PH: 'Philippines',
    PN: 'Pitcairn',
    PL: 'Poland',
    PT: 'Portugal',
    PR: 'Puerto Rico',
    QA: 'Qatar',
    RE: 'Reunion',
    RO: 'Romania',
    RU: 'Russia',
    RW: 'Rwanda',
    SH: 'Saint Helena',
    KN: 'Saint Kitts and Nevis',
    LC: 'Saint Lucia',
    PM: 'Saint Pierre and Miquelon',
    VC: 'Saint Vincent and the Grenadines',
    WS: 'Samoa',
    SM: 'San Marino',
    ST: 'Sao Tome and Principe',
    SA: 'Saudi Arabia',
    SN: 'Senegal',
    SC: 'Seychelles',
    SL: 'Sierra Leone',
    SG: 'Singapore',
    SK: 'Slovakia',
    SI: 'Slovenia',
    SB: 'Solomon Islands',
    SO: 'Somalia',
    ZA: 'South Africa',
    GS: 'South Georgia and the South Sandwich Islands',
    ES: 'Spain',
    LK: 'Sri Lanka',
    SD: 'Sudan',
    SR: 'Suriname',
    SJ: 'Svalbard and Jan Mayen',
    SZ: 'Eswatini',
    SE: 'Sweden',
    CH: 'Switzerland',
    SY: 'Syrian Arab Republic',
    TW: 'Taiwan',
    TJ: 'Tajikistan',
    TZ: 'Tanzania',
    TH: 'Thailand',
    TL: 'Timor-Leste',
    TG: 'Togo',
    TK: 'Tokelau',
    TO: 'Tonga',
    TT: 'Trinidad and Tobago',
    TN: 'Tunisia',
    TR: 'Türkiye',
    TM: 'Turkmenistan',
    TC: 'Turks and Caicos Islands',
    TV: 'Tuvalu',
    UG: 'Uganda',
    UA: 'Ukraine',
    AE: 'United Arab Emirates',
    GB: 'United Kingdom',
    US: 'USA',
    UM: 'United States Minor Outlying Islands',
    UY: 'Uruguay',
    UZ: 'Uzbekistan',
    VU: 'Vanuatu',
    VE: 'Venezuela',
    VN: 'Vietnam',
    VG: 'Virgin Islands, British',
    VI: 'Virgin Islands, U.S.',
    WF: 'Wallis and Futuna',
    EH: 'Western Sahara',
    YE: 'Yemen',
    ZM: 'Zambia',
    ZW: 'Zimbabwe',
    AX: 'Åland Islands',
    BQ: 'Bonaire, Sint Eustatius and Saba',
    CW: 'Curaçao',
    GG: 'Guernsey',
    IM: 'Isle of Man',
    JE: 'Jersey',
    ME: 'Montenegro',
    BL: 'Saint Barthélemy',
    MF: 'Saint Martin (French part)',
    RS: 'Serbia',
    SX: 'Sint Maarten (Dutch part)',
    SS: 'South Sudan',
    XK: 'Kosovo',
  },
}
export const de: CountryData = {
  locale: 'de',
  countries: {
    AF: 'Afghanistan',
    EG: 'Ägypten',
    AX: 'Åland',
    AL: 'Albanien',
    DZ: 'Algerien',
    AS: 'Amerikanisch-Samoa',
    VI: 'Amerikanische Jungferninseln',
    AD: 'Andorra',
    AO: 'Angola',
    AI: 'Anguilla',
    AQ: 'Antarktis',
    AG: 'Antigua und Barbuda',
    GQ: 'Äquatorialguinea',
    AR: 'Argentinien',
    AM: 'Armenien',
    AW: 'Aruba',
    AZ: 'Aserbaidschan',
    ET: 'Äthiopien',
    AU: 'Australien',
    BS: 'Bahamas',
    BH: 'Bahrain',
    BD: 'Bangladesch',
    BB: 'Barbados',
    BY: 'Belarus',
    BE: 'Belgien',
    BZ: 'Belize',
    BJ: 'Benin',
    BM: 'Bermuda',
    BT: 'Bhutan',
    BO: 'Bolivien',
    BQ: 'Bonaire',
    BA: 'Bosnien und Herzegowina',
    BW: 'Botswana',
    BV: 'Bouvetinsel',
    BR: 'Brasilien',
    VG: 'Britische Jungferninseln',
    IO: 'Britisches Territorium im Indischen Ozean',
    BN: 'Brunei Darussalam',
    BG: 'Bulgarien',
    BF: 'Burkina Faso',
    BI: 'Burundi',
    CL: 'Chile',
    CN: 'China',
    CK: 'Cookinseln',
    CR: 'Costa Rica',
    CI: 'Elfenbeinküste',
    CW: 'Curaçao',
    DK: 'Dänemark',
    DE: 'Deutschland',
    DM: 'Dominica',
    DO: 'Dominikanische Republik',
    DJ: 'Dschibuti',
    EC: 'Ecuador',
    SV: 'El Salvador',
    ER: 'Eritrea',
    EE: 'Estland',
    FK: 'Falklandinseln',
    FO: 'Färöer',
    FJ: 'Fidschi',
    FI: 'Finnland',
    FR: 'Frankreich',
    GF: 'Französisch-Guayana',
    PF: 'Französisch-Polynesien',
    TF: 'Französische Süd- und Antarktisgebiete',
    GA: 'Gabun',
    GM: 'Gambia',
    GE: 'Georgien',
    GH: 'Ghana',
    GI: 'Gibraltar',
    GD: 'Grenada',
    GR: 'Griechenland',
    GL: 'Grönland',
    GP: 'Guadeloupe',
    GU: 'Guam',
    GT: 'Guatemala',
    GG: 'Guernsey',
    GN: 'Guinea',
    GW: 'Guinea-Bissau',
    GY: 'Guyana',
    HT: 'Haiti',
    HM: 'Heard und McDonaldinseln',
    HN: 'Honduras',
    HK: 'Hongkong',
    IN: 'Indien',
    ID: 'Indonesien',
    IM: 'Insel Man',
    IQ: 'Irak',
    IR: 'Iran',
    IE: 'Irland',
    IS: 'Island',
    IL: 'Israel',
    IT: 'Italien',
    JM: 'Jamaika',
    JP: 'Japan',
    YE: 'Jemen',
    JE: 'Jersey',
    JO: 'Jordanien',
    KY: 'Kaimaninseln',
    KH: 'Kambodscha',
    CM: 'Kamerun',
    CA: 'Kanada',
    CV: 'Kap Verde',
    KZ: 'Kasachstan',
    QA: 'Katar',
    KE: 'Kenia',
    KG: 'Kirgisistan',
    KI: 'Kiribati',
    CC: 'Kokosinseln',
    CO: 'Kolumbien',
    KM: 'Komoren',
    CD: 'Kongo',
    KP: 'Nordkorea',
    KR: 'Südkorea',
    HR: 'Kroatien',
    CU: 'Kuba',
    KW: 'Kuwait',
    LA: 'Laos',
    LS: 'Lesotho',
    LV: 'Lettland',
    LB: 'Libanon',
    LR: 'Liberia',
    LY: 'Libyen',
    LI: 'Liechtenstein',
    LT: 'Litauen',
    LU: 'Luxemburg',
    MO: 'Macao',
    MG: 'Madagaskar',
    MW: 'Malawi',
    MY: 'Malaysia',
    MV: 'Malediven',
    ML: 'Mali',
    MT: 'Malta',
    MA: 'Marokko',
    MH: 'Marshallinseln',
    MQ: 'Martinique',
    MR: 'Mauretanien',
    MU: 'Mauritius',
    YT: 'Mayotte',
    MX: 'Mexiko',
    FM: 'Mikronesien',
    MD: 'Moldawien',
    MC: 'Monaco',
    MN: 'Mongolei',
    ME: 'Montenegro',
    MS: 'Montserrat',
    MZ: 'Mosambik',
    MM: 'Myanmar',
    NA: 'Namibia',
    NR: 'Nauru',
    NP: 'Nepal',
    NC: 'Neukaledonien',
    NZ: 'Neuseeland',
    NI: 'Nicaragua',
    NL: 'Niederlande',
    NE: 'Niger',
    NG: 'Nigeria',
    NU: 'Niue',
    MK: 'Nordmazedonien',
    MP: 'Nördliche Marianen',
    NF: 'Norfolkinsel',
    NO: 'Norwegen',
    OM: 'Oman',
    AT: 'Österreich',
    TL: 'Osttimor',
    PK: 'Pakistan',
    PS: 'Staat Palästina',
    PW: 'Palau',
    PA: 'Panama',
    PG: 'Papua-Neuguinea',
    PY: 'Paraguay',
    PE: 'Peru',
    PH: 'Philippinen',
    PN: 'Pitcairninseln',
    PL: 'Polen',
    PT: 'Portugal',
    PR: 'Puerto Rico',
    TW: 'Taiwan',
    CG: 'Republik Kongo',
    RE: 'Réunion',
    RW: 'Ruanda',
    RO: 'Rumänien',
    RU: 'Russische Föderation',
    BL: 'Saint-Barthélemy',
    MF: 'Saint-Martin',
    SB: 'Salomonen',
    ZM: 'Sambia',
    WS: 'Samoa',
    SM: 'San Marino',
    ST: 'São Tomé und Príncipe',
    SA: 'Saudi-Arabien',
    SE: 'Schweden',
    CH: 'Schweiz',
    SN: 'Senegal',
    RS: 'Serbien',
    SC: 'Seychellen',
    SL: 'Sierra Leone',
    ZW: 'Simbabwe',
    SG: 'Singapur',
    SX: 'Sint Maarten',
    SK: 'Slowakei',
    SI: 'Slowenien',
    SO: 'Somalia',
    ES: 'Spanien',
    LK: 'Sri Lanka',
    SH: 'St. Helena',
    KN: 'St. Kitts und Nevis',
    LC: 'St. Lucia',
    PM: 'Saint-Pierre und Miquelon',
    VC: 'St. Vincent und die Grenadinen',
    ZA: 'Südafrika',
    SD: 'Sudan',
    GS: 'Südgeorgien und die Südlichen Sandwichinseln',
    SS: 'Südsudan',
    SR: 'Suriname',
    SJ: 'Svalbard und Jan Mayen',
    SZ: 'Eswatini',
    SY: 'Syrien, Arabische Republik',
    TJ: 'Tadschikistan',
    TZ: 'Tansania, Vereinigte Republik',
    TH: 'Thailand',
    TG: 'Togo',
    TK: 'Tokelau',
    TO: 'Tonga',
    TT: 'Trinidad und Tobago',
    TD: 'Tschad',
    CZ: 'Tschechische Republik',
    TN: 'Tunesien',
    TR: 'Türkei',
    TM: 'Turkmenistan',
    TC: 'Turks- und Caicosinseln',
    TV: 'Tuvalu',
    UG: 'Uganda',
    UA: 'Ukraine',
    HU: 'Ungarn',
    UM: 'United States Minor Outlying Islands',
    UY: 'Uruguay',
    UZ: 'Usbekistan',
    VU: 'Vanuatu',
    VA: 'Vatikanstadt',
    VE: 'Venezuela',
    AE: 'Vereinigte Arabische Emirate',
    US: 'USA',
    GB: 'Großbritannien',
    VN: 'Vietnam',
    WF: 'Wallis und Futuna',
    CX: 'Weihnachtsinsel',
    EH: 'Westsahara',
    CF: 'Zentralafrikanische Republik',
    CY: 'Zypern',
    XK: 'Kosovo',
  },
}
export const it: CountryData = {
  locale: 'it',
  countries: {
    AD: 'Andorra',
    AE: 'Emirati Arabi Uniti',
    AF: 'Afghanistan',
    AG: 'Antigua e Barbuda',
    AI: 'Anguilla',
    AL: 'Albania',
    AM: 'Armenia',
    AO: 'Angola',
    AQ: 'Antartide',
    AR: 'Argentina',
    AS: 'Samoa Americane',
    AT: 'Austria',
    AU: 'Australia',
    AW: 'Aruba',
    AX: 'Isole Åland',
    AZ: 'Azerbaigian',
    BA: 'Bosnia ed Erzegovina',
    BB: 'Barbados',
    BD: 'Bangladesh',
    BE: 'Belgio',
    BF: 'Burkina Faso',
    BG: 'Bulgaria',
    BH: 'Bahrain',
    BI: 'Burundi',
    BJ: 'Benin',
    BL: 'Saint-Barthélemy',
    BM: 'Bermuda',
    BN: 'Brunei',
    BO: 'Bolivia',
    BQ: 'Isole BES',
    BR: 'Brasile',
    BS: 'Bahamas',
    BT: 'Bhutan',
    BV: 'Isola Bouvet',
    BW: 'Botswana',
    BY: 'Bielorussia',
    BZ: 'Belize',
    CA: 'Canada',
    CC: 'Isole Cocos e Keeling',
    CD: 'Repubblica Democratica del Congo',
    CF: 'Repubblica Centrafricana',
    CG: 'Repubblica del Congo',
    CH: 'Svizzera',
    CI: "Costa d'Avorio",
    CK: 'Isole Cook',
    CL: 'Cile',
    CM: 'Camerun',
    CN: 'Cina',
    CO: 'Colombia',
    CR: 'Costa Rica',
    CU: 'Cuba',
    CV: 'Capo Verde',
    CW: 'Curaçao',
    CX: 'Isola del Natale',
    CY: 'Cipro',
    CZ: 'Repubblica Ceca',
    DE: 'Germania',
    DJ: 'Gibuti',
    DK: 'Danimarca',
    DM: 'Dominica',
    DO: 'Repubblica Dominicana',
    DZ: 'Algeria',
    EC: 'Ecuador',
    EE: 'Estonia',
    EG: 'Egitto',
    EH: 'Sahara Occidentale',
    ER: 'Eritrea',
    ES: 'Spagna',
    ET: 'Etiopia',
    FI: 'Finlandia',
    FJ: 'Figi',
    FK: 'Isole Falkland',
    FM: 'Stati Federati di Micronesia',
    FO: 'Isole Fær Øer',
    FR: 'Francia',
    GA: 'Gabon',
    GB: 'Regno Unito',
    GD: 'Grenada',
    GE: 'Georgia',
    GF: 'Guyana Francese',
    GG: 'Guernsey',
    GH: 'Ghana',
    GI: 'Gibilterra',
    GL: 'Groenlandia',
    GM: 'Gambia',
    GN: 'Guinea',
    GP: 'Guadalupa',
    GQ: 'Guinea Equatoriale',
    GR: 'Grecia',
    GS: 'Georgia del Sud e isole Sandwich meridionali',
    GT: 'Guatemala',
    GU: 'Guam',
    GW: 'Guinea-Bissau',
    GY: 'Guyana',
    HK: 'Hong Kong',
    HM: 'Isole Heard e McDonald',
    HN: 'Honduras',
    HR: 'Croazia',
    HT: 'Haiti',
    HU: 'Ungheria',
    ID: 'Indonesia',
    IE: 'Irlanda',
    IL: 'Israele',
    IM: 'Isola di Man',
    IN: 'India',
    IO: "Territori Britannici dell'Oceano Indiano",
    IQ: 'Iraq',
    IR: 'Iran',
    IS: 'Islanda',
    IT: 'Italia',
    JE: 'Jersey',
    JM: 'Giamaica',
    JO: 'Giordania',
    JP: 'Giappone',
    KE: 'Kenya',
    KG: 'Kirghizistan',
    KH: 'Cambogia',
    KI: 'Kiribati',
    KM: 'Comore',
    KN: 'Saint Kitts e Nevis',
    KP: 'Corea del Nord',
    KR: 'Corea del Sud',
    KW: 'Kuwait',
    KY: 'Isole Cayman',
    KZ: 'Kazakistan',
    LA: 'Laos',
    LB: 'Libano',
    LC: 'Santa Lucia',
    LI: 'Liechtenstein',
    LK: 'Sri Lanka',
    LR: 'Liberia',
    LS: 'Lesotho',
    LT: 'Lituania',
    LU: 'Lussemburgo',
    LV: 'Lettonia',
    LY: 'Libia',
    MA: 'Marocco',
    MC: 'Monaco',
    MD: 'Moldavia',
    ME: 'Montenegro',
    MF: 'Saint-Martin',
    MG: 'Madagascar',
    MH: 'Isole Marshall',
    MK: 'Macedonia del Nord',
    ML: 'Mali',
    MM: 'Birmania  Myanmar',
    MN: 'Mongolia',
    MO: 'Macao',
    MP: 'Isole Marianne Settentrionali',
    MQ: 'Martinica',
    MR: 'Mauritania',
    MS: 'Montserrat',
    MT: 'Malta',
    MU: 'Mauritius',
    MV: 'Maldive',
    MW: 'Malawi',
    MX: 'Messico',
    MY: 'Malesia',
    MZ: 'Mozambico',
    NA: 'Namibia',
    NC: 'Nuova Caledonia',
    NE: 'Niger',
    NF: 'Isola Norfolk',
    NG: 'Nigeria',
    NI: 'Nicaragua',
    NL: 'Paesi Bassi',
    NO: 'Norvegia',
    NP: 'Nepal',
    NR: 'Nauru',
    NU: 'Niue',
    NZ: 'Nuova Zelanda',
    OM: 'Oman',
    PA: 'Panamá',
    PE: 'Perù',
    PF: 'Polinesia Francese',
    PG: 'Papua Nuova Guinea',
    PH: 'Filippine',
    PK: 'Pakistan',
    PL: 'Polonia',
    PM: 'Saint Pierre e Miquelon',
    PN: 'Isole Pitcairn',
    PR: 'Porto Rico',
    PS: 'Stato di Palestina',
    PT: 'Portogallo',
    PW: 'Palau',
    PY: 'Paraguay',
    QA: 'Qatar',
    RE: 'Réunion',
    RO: 'Romania',
    RS: 'Serbia',
    RU: 'Russia',
    RW: 'Ruanda',
    SA: 'Arabia Saudita',
    SB: 'Isole Salomone',
    SC: 'Seychelles',
    SD: 'Sudan',
    SE: 'Svezia',
    SG: 'Singapore',
    SH: "Sant'Elena, Isola di Ascensione e Tristan da Cunha",
    SI: 'Slovenia',
    SJ: 'Svalbard e Jan Mayen',
    SK: 'Slovacchia',
    SL: 'Sierra Leone',
    SM: 'San Marino',
    SN: 'Senegal',
    SO: 'Somalia',
    SR: 'Suriname',
    SS: 'Sudan del Sud',
    ST: 'São Tomé e Príncipe',
    SV: 'El Salvador',
    SX: 'Sint Maarten',
    SY: 'Siria',
    SZ: 'Eswatini',
    TC: 'Isole Turks e Caicos',
    TD: 'Ciad',
    TF: 'Territori Francesi del Sud',
    TG: 'Togo',
    TH: 'Thailandia',
    TJ: 'Tagikistan',
    TK: 'Tokelau',
    TL: 'Timor Est',
    TM: 'Turkmenistan',
    TN: 'Tunisia',
    TO: 'Tonga',
    TR: 'Turchia',
    TT: 'Trinidad e Tobago',
    TV: 'Tuvalu',
    TW: 'Taiwan',
    TZ: 'Tanzania',
    UA: 'Ucraina',
    UG: 'Uganda',
    UM: 'Isole minori esterne degli Stati Uniti',
    US: "Stati Uniti d'America",
    UY: 'Uruguay',
    UZ: 'Uzbekistan',
    VA: 'Città del Vaticano',
    VC: 'Saint Vincent e Grenadine',
    VE: 'Venezuela',
    VG: 'Isole Vergini Britanniche',
    VI: 'Isole Vergini Americane',
    VN: 'Vietnam',
    VU: 'Vanuatu',
    WF: 'Wallis e Futuna',
    WS: 'Samoa',
    YE: 'Yemen',
    YT: 'Mayotte',
    ZA: 'Sudafrica',
    ZM: 'Zambia',
    ZW: 'Zimbabwe',
    XK: 'Kosovo',
  },
}
export const es: CountryData = {
  locale: 'es',
  countries: {
    AF: 'Afganistán',
    AL: 'Albania',
    DZ: 'Argelia',
    AS: 'Samoa Americana',
    AD: 'Andorra',
    AO: 'Angola',
    AI: 'Anguila',
    AQ: 'Antártida',
    AG: 'Antigua y Barbuda',
    AR: 'Argentina',
    AM: 'Armenia',
    AW: 'Aruba',
    AU: 'Australia',
    AT: 'Austria',
    AZ: 'Azerbaiyán',
    BS: 'Bahamas',
    BH: 'Bahrein',
    BD: 'Bangladesh',
    BB: 'Barbados',
    BY: 'Bielorrusia',
    BE: 'Bélgica',
    BZ: 'Belice',
    BJ: 'Benin',
    BM: 'Bermudas',
    BT: 'Bután',
    BO: 'Bolivia',
    BA: 'Bosnia y Herzegovina',
    BW: 'Botswana',
    BV: 'Isla Bouvet',
    BR: 'Brasil',
    IO: 'Territorio Británico del Océano Índico',
    BN: 'Brunei Darussalam',
    BG: 'Bulgaria',
    BF: 'Burkina Faso',
    BI: 'Burundi',
    KH: 'Camboya',
    CM: 'Camerún',
    CA: 'Canadá',
    CV: 'Cabo Verde',
    KY: 'Islas Caimán',
    CF: 'República Centroafricana',
    TD: 'Chad',
    CL: 'Chile',
    CN: 'China',
    CX: 'Isla de Navidad',
    CC: 'Islas Cocos (Keeling)',
    CO: 'Colombia',
    KM: 'Comoras',
    CG: 'Congo',
    CD: 'Congo (República Democrática del)',
    CK: 'Islas Cook',
    CR: 'Costa Rica',
    CI: 'Costa de Marfil',
    HR: 'Croacia',
    CU: 'Cuba',
    CY: 'Chipre',
    CZ: 'República Checa',
    DK: 'Dinamarca',
    DJ: 'Yibuti',
    DM: 'Dominica',
    DO: 'República Dominicana',
    EC: 'Ecuador',
    EG: 'Egipto',
    SV: 'El Salvador',
    GQ: 'Guinea Ecuatorial',
    ER: 'Eritrea',
    EE: 'Estonia',
    ET: 'Etiopía',
    FK: 'Islas Malvinas',
    FO: 'Islas Feroe',
    FJ: 'Fiji',
    FI: 'Finlandia',
    FR: 'Francia',
    GF: 'Guayana Francesa',
    PF: 'Polinesia Francesa',
    TF: 'Tierras Australes Francesas',
    GA: 'Gabón',
    GM: 'Gambia',
    GE: 'Georgia',
    DE: 'Alemania',
    GH: 'Ghana',
    GI: 'Gibraltar',
    GR: 'Grecia',
    GL: 'Groenlandia',
    GD: 'Granada',
    GP: 'Guadalupe',
    GU: 'Guam',
    GT: 'Guatemala',
    GN: 'Guinea',
    GW: 'Guinea Bissau',
    GY: 'Guyana',
    HT: 'Haití',
    HM: 'Heard e Islas McDonald',
    VA: 'Santa Sede',
    HN: 'Honduras',
    HK: 'Hong Kong',
    HU: 'Hungría',
    IS: 'Islandia',
    IN: 'India',
    ID: 'Indonesia',
    IR: 'Irán',
    IQ: 'Iraq',
    IE: 'Irlanda',
    IL: 'Israel',
    IT: 'Italia',
    JM: 'Jamaica',
    JP: 'Japón',
    JO: 'Jordania',
    KZ: 'Kazajistán',
    KE: 'Kenia',
    KI: 'Kiribati',
    KP: 'República Popular Democrática de Corea',
    KR: 'República de Corea',
    KW: 'Kuwait',
    KG: 'Kirguistán',
    LA: 'República Democrática Popular de Lao',
    LV: 'Letonia',
    LB: 'Líbano',
    LS: 'Lesoto',
    LR: 'Liberia',
    LY: 'Libia',
    LI: 'Liechtenstein',
    LT: 'Lituania',
    LU: 'Luxemburgo',
    MO: 'Macao',
    MK: 'Macedonia del Norte',
    MG: 'Madagascar',
    MW: 'Malaui',
    MY: 'Malasia',
    MV: 'Maldivas',
    ML: 'Malí',
    MT: 'Malta',
    MH: 'Islas Marshall',
    MQ: 'Martinica',
    MR: 'Mauritania',
    MU: 'Mauricio',
    YT: 'Mayotte',
    MX: 'México',
    FM: 'Micronesia',
    MD: 'Moldavia',
    MC: 'Mónaco',
    MN: 'Mongolia',
    MS: 'Montserrat',
    MA: 'Marruecos',
    MZ: 'Mozambique',
    MM: 'Myanmar',
    NA: 'Namibia',
    NR: 'Nauru',
    NP: 'Nepal',
    NL: 'Países Bajos',
    NC: 'Nueva Caledonia',
    NZ: 'Nueva Zelanda',
    NI: 'Nicaragua',
    NE: 'Níger',
    NG: 'Nigeria',
    NU: 'Niue',
    NF: 'Isla Norfolk',
    MP: 'Islas Marianas del Norte',
    NO: 'Noruega',
    OM: 'Omán',
    PK: 'Pakistán',
    PW: 'Palau',
    PS: 'Palestina',
    PA: 'Panamá',
    PG: 'Papua Nueva Guinea',
    PY: 'Paraguay',
    PE: 'Perú',
    PH: 'Filipinas',
    PN: 'Pitcairn',
    PL: 'Polonia',
    PT: 'Portugal',
    PR: 'Puerto Rico',
    QA: 'Catar',
    RE: 'Reunión',
    RO: 'Rumanía',
    RU: 'Rusia',
    RW: 'Ruanda',
    SH: 'Santa Helena, Ascensión y Tristán de Acuña',
    KN: 'Saint Kitts y Nevis',
    LC: 'Santa Lucía',
    PM: 'San Pedro y Miquelón',
    VC: 'San Vicente y las Granadinas',
    WS: 'Samoa',
    SM: 'San Marino',
    ST: 'Santo Tomé y Príncipe',
    SA: 'Arabia Saudita',
    SN: 'Senegal',
    SC: 'Seychelles',
    SL: 'Sierra Leona',
    SG: 'Singapur',
    SK: 'Eslovaquia',
    SI: 'Eslovenia',
    SB: 'Islas Salomón',
    SO: 'Somalia',
    ZA: 'Sudáfrica',
    GS: 'Georgia del Sur y las Islas Sandwich del Sur',
    ES: 'España',
    LK: 'Sri Lanka',
    SD: 'Sudán',
    SR: 'Suriname',
    SJ: 'Svalbard y Jan Mayen',
    SZ: 'Suazilandia',
    SE: 'Suecia',
    CH: 'Suiza',
    SY: 'República Árabe Siria',
    TW: 'Taiwán',
    TJ: 'Tayikistán',
    TZ: 'Tanzania',
    TH: 'Tailandia',
    TL: 'Timor-Leste',
    TG: 'Togo',
    TK: 'Tokelau',
    TO: 'Tonga',
    TT: 'Trinidad y Tobago',
    TN: 'Túnez',
    TR: 'Turquía',
    TM: 'Turkmenistán',
    TC: 'Islas Turcas y Caicos',
    TV: 'Tuvalu',
    UG: 'Uganda',
    UA: 'Ucrania',
    AE: 'Emiratos Árabes Unidos',
    GB: 'Reino Unido',
    US: 'Estados Unidos',
    UM: 'Islas Ultramarinas Menores de los Estados Unidos',
    UY: 'Uruguay',
    UZ: 'Uzbekistán',
    VU: 'Vanuatu',
    VE: 'Venezuela',
    VN: 'Vietnam',
    VG: 'Islas Vírgenes británicas',
    VI: 'Islas Vírgenes de los Estados Unidos',
    WF: 'Wallis y Futuna',
    EH: 'Sahara Occidental',
    YE: 'Yemen',
    ZM: 'Zambia',
    ZW: 'Zimbabue',
    AX: 'Islas Åland',
    BQ: 'Bonaire, San Eustaquio y Saba',
    CW: 'Curaçao',
    GG: 'Guernsey',
    IM: 'Isla de Man',
    JE: 'Jersey',
    ME: 'Montenegro',
    BL: 'Saint Barthélemy',
    MF: 'Saint Martin (francesa)',
    RS: 'Serbia',
    SX: 'Sint Maarten (neerlandesa)',
    SS: 'Sudán del Sur',
    XK: 'Kosovo',
  },
}
export const nl: CountryData = {
  locale: 'nl',
  countries: {
    AF: 'Afghanistan',
    AL: 'Albanië',
    DZ: 'Algerije',
    AS: 'Amerikaans-Samoa',
    AD: 'Andorra',
    AO: 'Angola',
    AI: 'Anguilla',
    AQ: 'Antarctica',
    AG: 'Antigua en Barbuda',
    AR: 'Argentinië',
    AM: 'Armenië',
    AW: 'Aruba',
    AU: 'Australië',
    AT: 'Oostenrijk',
    AZ: 'Azerbeidzjan',
    BS: "Bahama's",
    BH: 'Bahrein',
    BD: 'Bangladesh',
    BB: 'Barbados',
    BY: 'Wit-Rusland',
    BE: 'België',
    BZ: 'Belize',
    BJ: 'Benin',
    BM: 'Bermuda',
    BT: 'Bhutan',
    BO: 'Bolivië',
    BA: 'Bosnië-Herzegovina',
    BW: 'Botswana',
    BV: 'Bouvet Eiland',
    BR: 'Brazilië',
    IO: 'Brits Indische oceaan',
    BN: 'Brunei Darussalam',
    BG: 'Bulgarije',
    BF: 'Burkina Faso',
    BI: 'Burundi',
    KH: 'Cambodja',
    CM: 'Kameroen',
    CA: 'Canada',
    CV: 'Kaapverdië',
    KY: 'Kaaimaneilanden',
    CF: 'Centraal-Afrikaanse Republiek',
    TD: 'Tsjaad',
    CL: 'Chili',
    CN: 'China',
    CX: 'Christmaseiland',
    CC: 'Cocoseilanden',
    CO: 'Colombia',
    KM: 'Comoren',
    CG: 'Congo, Volksrepubliek',
    CD: 'Congo, Democratische Republiek',
    CK: 'Cookeilanden',
    CR: 'Costa Rica',
    CI: 'Ivoorkust',
    HR: 'Kroatië',
    CU: 'Cuba',
    CY: 'Cyprus',
    CZ: 'Tsjechië',
    DK: 'Denemarken',
    DJ: 'Djibouti',
    DM: 'Dominica',
    DO: 'Dominicaanse Republiek',
    EC: 'Ecuador',
    EG: 'Egypte',
    SV: 'El Salvador',
    GQ: 'Equatoriaal-Guinea',
    ER: 'Eritrea',
    EE: 'Estland',
    ET: 'Ethiopië',
    FK: 'Falklandeilanden',
    FO: 'Faeröer',
    FJ: 'Fiji',
    FI: 'Finland',
    FR: 'Frankrijk',
    GF: 'Frans-Guyana',
    PF: 'Frans-Polynesië',
    TF: 'Franse Zuidelijke Gebieden',
    GA: 'Gabon',
    GM: 'Gambia',
    GE: 'Georgië',
    DE: 'Duitsland',
    GH: 'Ghana',
    GI: 'Gibraltar',
    GR: 'Griekenland',
    GL: 'Groenland',
    GD: 'Grenada',
    GP: 'Guadeloupe',
    GU: 'Guam',
    GT: 'Guatemala',
    GN: 'Guinea',
    GW: 'Guinee-Bissau',
    GY: 'Guyana',
    HT: 'Haïti',
    HM: 'Heard en McDonaldeilanden',
    VA: 'Heilige Stoel',
    HN: 'Honduras',
    HK: 'Hong Kong',
    HU: 'Hongarije',
    IS: 'IJsland',
    IN: 'India',
    ID: 'Indonesië',
    IR: 'Iran',
    IQ: 'Irak',
    IE: 'Ierland',
    IL: 'Israël',
    IT: 'Italië',
    JM: 'Jamaica',
    JP: 'Japan',
    JO: 'Jordanië',
    KZ: 'Kazachstan',
    KE: 'Kenia',
    KI: 'Kiribati',
    KP: 'Noord-Korea',
    KR: 'Zuid-Korea',
    KW: 'Koeweit',
    KG: 'Kirgizstan',
    LA: 'Laos',
    LV: 'Letland',
    LB: 'Libanon',
    LS: 'Lesotho',
    LR: 'Liberia',
    LY: 'Libië',
    LI: 'Liechtenstein',
    LT: 'Litouwen',
    LU: 'Luxemburg',
    MO: 'Macao',
    MG: 'Madagaskar',
    MW: 'Malawi',
    MY: 'Maleisië',
    MV: 'Maldiven',
    ML: 'Mali',
    MT: 'Malta',
    MH: 'Marshalleilanden',
    MQ: 'Martinique',
    MR: 'Mauritanië',
    MU: 'Mauritius',
    YT: 'Mayotte',
    MX: 'Mexico',
    FM: 'Micronesië, Federale Staten',
    MD: 'Moldavië',
    MC: 'Monaco',
    MN: 'Mongolië',
    MS: 'Montserrat',
    MA: 'Marokko',
    MZ: 'Mozambique',
    MM: 'Myanmar',
    NA: 'Namibië',
    NR: 'Nauru',
    NP: 'Nepal',
    NL: 'Nederland',
    NC: 'Nieuw-Caledonië',
    NZ: 'Nieuw-Zeeland',
    NI: 'Nicaragua',
    NE: 'Niger',
    NG: 'Nigeria',
    NU: 'Niue',
    NF: 'Norfolk',
    MP: 'Noordelijke Marianen',
    MK: 'Noord-Macedonië',
    NO: 'Noorwegen',
    OM: 'Oman',
    PK: 'Pakistan',
    PW: 'Palau',
    PS: 'Palestina',
    PA: 'Panama',
    PG: 'Papoea-Nieuw-Guinea',
    PY: 'Paraguay',
    PE: 'Peru',
    PH: 'Filipijnen',
    PN: 'Pitcairn',
    PL: 'Polen',
    PT: 'Portugal',
    PR: 'Puerto Rico',
    QA: 'Qatar',
    RE: 'Réunion',
    RO: 'Roemenië',
    RU: 'Rusland',
    RW: 'Rwanda',
    SH: 'Sint-Helena',
    KN: 'Saint Kitts en Nevis',
    LC: 'Saint Lucia',
    PM: 'Saint-Pierre en Miquelon',
    VC: 'Saint Vincent en de Grenadines',
    WS: 'Samoa',
    SM: 'San Marino',
    ST: 'São Tomé en Principe',
    SA: 'Saudi-Arabië',
    SN: 'Senegal',
    SC: 'Seychellen',
    SL: 'Sierra Leone',
    SG: 'Singapore',
    SK: 'Slowakije',
    SI: 'Slovenië',
    SB: 'Salomonseilanden',
    SO: 'Somalië',
    ZA: 'Zuid-Afrika',
    GS: 'Zuid-Georgia en de Zuidelijke Sandwicheilanden',
    ES: 'Spanje',
    LK: 'Sri Lanka',
    SD: 'Soedan',
    SR: 'Suriname',
    SJ: 'Spitsbergen en Jan Mayen',
    SZ: 'Ngwane, Koninkrijk Eswatini',
    SE: 'Zweden',
    CH: 'Zwitserland',
    SY: 'Syrië',
    TW: 'Taiwan',
    TJ: 'Tadzjikistan',
    TZ: 'Tanzania, Verenigde Republiek',
    TH: 'Thailand',
    TL: 'Timor Leste',
    TG: 'Togo',
    TK: 'Tokelau',
    TO: 'Tonga',
    TT: 'Trinidad en Tobago',
    TN: 'Tunesië',
    TR: 'Turkije',
    TM: 'Turkmenistan',
    TC: 'Turks- en Caicoseilanden',
    TV: 'Tuvalu',
    UG: 'Oeganda',
    UA: 'Oekraïne',
    AE: 'Verenigde Arabische Emiraten',
    GB: 'Groot-Brittannië',
    US: 'Verenigde Staten van Amerika',
    UM: 'Ver afgelegen eilandjes van de Verenigde Staten',
    UY: 'Uruguay',
    UZ: 'Oezbekistan',
    VU: 'Vanuatu',
    VE: 'Venezuela',
    VN: 'Vietnam',
    VG: 'Maagdeneilanden, Britse',
    VI: 'Maagdeneilanden, Amerikaanse',
    WF: 'Wallis en Futuna',
    EH: 'Westelijke Sahara',
    YE: 'Jemen',
    ZM: 'Zambia',
    ZW: 'Zimbabwe',
    AX: 'Åland',
    BQ: 'Bonaire, Sint Eustatius en Saba',
    CW: 'Curaçao',
    GG: 'Guernsey',
    IM: 'Man Eiland',
    JE: 'Jersey',
    ME: 'Montenegro',
    BL: 'Saint Barthélemy',
    MF: 'Sint-Maarten (Franse Antillen)',
    RS: 'Servië',
    SX: 'Sint Maarten',
    SS: 'Zuid-Soedan',
    XK: 'Kosovo',
  },
}
export const pt: CountryData = {
  locale: 'pt',
  countries: {
    AF: 'Afeganistão',
    ZA: 'África do Sul',
    AL: 'Albânia',
    DE: 'Alemanha',
    AD: 'Andorra',
    AO: 'Angola',
    AI: 'Anguilla',
    AQ: 'Antártida',
    AG: 'Antígua e Barbuda',
    SA: 'Arábia Saudita',
    DZ: 'Argélia',
    AR: 'Argentina',
    AM: 'Armênia',
    AW: 'Aruba',
    AU: 'Austrália',
    AT: 'Áustria',
    AZ: 'Azerbaijão',
    BS: 'Bahamas',
    BH: 'Bahrein',
    BD: 'Bangladesh',
    BB: 'Barbados',
    BE: 'Bélgica',
    BZ: 'Belize',
    BJ: 'Benin',
    BM: 'Bermudas',
    BY: 'Bielorrússia',
    BO: 'Bolívia',
    BA: 'Bósnia e Herzegovina',
    BW: 'Botsuana',
    BR: 'Brasil',
    BN: 'Brunei',
    BG: 'Bulgária',
    BF: 'Burquina Faso',
    BI: 'Burundi',
    BT: 'Butão',
    CV: 'Cabo Verde',
    KH: 'Camboja',
    CA: 'Canadá',
    QA: 'Catar',
    KZ: 'Cazaquistão',
    TD: 'Chade',
    CL: 'Chile',
    CN: 'China',
    CY: 'Chipre',
    VA: 'Cidade do Vaticano',
    SG: 'Singapura',
    CO: 'Colômbia',
    KM: 'Comores',
    CG: 'Congo - Brazzaville',
    CD: 'Congo - Kinshasa',
    KP: 'Coreia do Norte',
    KR: 'Coreia do Sul',
    CI: 'Costa do Marfim',
    CR: 'Costa Rica',
    HR: 'Croácia',
    CU: 'Cuba',
    CW: 'Curaçao',
    DK: 'Dinamarca',
    DJ: 'Djibuti',
    DM: 'Dominica',
    EG: 'Egito',
    SV: 'El Salvador',
    AE: 'Emirados Árabes Unidos',
    EC: 'Equador',
    ER: 'Eritreia',
    SK: 'Eslováquia',
    SI: 'Eslovênia',
    ES: 'Espanha',
    US: 'Estados Unidos da América',
    EE: 'Estônia',
    ET: 'Etiópia',
    FJ: 'Fiji',
    PH: 'Filipinas',
    FI: 'Finlândia',
    FR: 'França',
    GA: 'Gabão',
    GM: 'Gâmbia',
    GH: 'Gana',
    GE: 'Geórgia',
    GS: 'Geórgia do Sul e Ilhas Sandwich do Sul',
    GI: 'Gibraltar',
    GD: 'Granada',
    GR: 'Grécia',
    GL: 'Groenlândia',
    GP: 'Guadalupe',
    GU: 'Guam',
    GT: 'Guatemala',
    GG: 'Guernsey',
    GY: 'Guiana',
    GF: 'Guiana Francesa',
    GN: 'Guiné',
    GW: 'Guiné Bissau',
    GQ: 'Guiné Equatorial',
    HT: 'Haiti',
    NL: 'Holanda',
    HN: 'Honduras',
    HK: 'Hong Kong, RAE da China',
    HU: 'Hungria',
    YE: 'Iêmen',
    BV: 'Ilhas Bouvet',
    CX: 'Ilha Christmas',
    IM: 'Ilha de Man',
    NF: 'Ilha Norfolk',
    AX: 'Ilhas Åland',
    KY: 'Ilhas Caiman',
    CC: 'Ilhas Cocos (Keeling)',
    CK: 'Ilhas Cook',
    UM: 'Ilhas Distantes dos EUA',
    HM: 'Ilha Heard e Ilha McDonald',
    FO: 'Ilhas Faroe',
    FK: 'Ilhas Malvinas',
    MP: 'Ilhas Marianas do Norte',
    MH: 'Ilhas Marshall',
    PN: 'Ilhas Pitcairn',
    SB: 'Ilhas Salomão',
    TC: 'Ilhas Turks e Caicos',
    VG: 'Ilhas Virgens Britânicas',
    VI: 'Ilhas Virgens dos EUA',
    IN: 'Índia',
    ID: 'Indonésia',
    IR: 'Irã',
    IQ: 'Iraque',
    IE: 'Irlanda',
    IS: 'Islândia',
    IL: 'Israel',
    IT: 'Itália',
    JM: 'Jamaica',
    JP: 'Japão',
    JE: 'Jersey',
    JO: 'Jordânia',
    KW: 'Kuwait',
    LA: 'Laos',
    LS: 'Lesoto',
    LV: 'Letônia',
    LB: 'Líbano',
    LR: 'Libéria',
    LY: 'Líbia',
    LI: 'Liechtenstein',
    LT: 'Lituânia',
    LU: 'Luxemburgo',
    MO: 'Macau, RAE da China',
    MK: 'Macedônia do Norte',
    MG: 'Madagascar',
    MY: 'Malásia',
    MW: 'Malawi',
    MV: 'Maldivas',
    ML: 'Mali',
    MT: 'Malta',
    MA: 'Marrocos',
    MQ: 'Martinica',
    MU: 'Maurício',
    MR: 'Mauritânia',
    YT: 'Mayotte',
    MX: 'México',
    MM: 'Mianmar (Birmânia)',
    FM: 'Micronésia',
    MZ: 'Moçambique',
    MD: 'Moldávia',
    MC: 'Mônaco',
    MN: 'Mongólia',
    ME: 'Montenegro',
    MS: 'Montserrat',
    NA: 'Namíbia',
    NR: 'Nauru',
    NP: 'Nepal',
    NI: 'Nicarágua',
    NE: 'Níger',
    NG: 'Nigéria',
    NU: 'Niue',
    NO: 'Noruega',
    NC: 'Nova Caledônia',
    NZ: 'Nova Zelândia',
    OM: 'Omã',
    BQ: 'Países Baixos Caribenhos',
    PW: 'Palau',
    PA: 'Panamá',
    PG: 'Papua-Nova Guiné',
    PK: 'Paquistão',
    PY: 'Paraguai',
    PE: 'Peru',
    PF: 'Polinésia Francesa',
    PL: 'Polônia',
    PR: 'Porto Rico',
    PT: 'Portugal',
    KE: 'Quênia',
    KG: 'Quirguistão',
    KI: 'Quiribati',
    GB: 'Reino Unido',
    CF: 'República Centro-Africana',
    DO: 'República Dominicana',
    CM: 'República dos Camarões',
    CZ: 'República Tcheca',
    RE: 'Reunião',
    RO: 'Romênia',
    RW: 'Ruanda',
    RU: 'Rússia',
    EH: 'Saara Ocidental',
    PM: 'Saint Pierre e Miquelon',
    WS: 'Samoa',
    AS: 'Samoa Americana',
    SM: 'San Marino',
    SH: 'Santa Helena',
    LC: 'Santa Lúcia',
    BL: 'São Bartolomeu',
    KN: 'São Cristóvão e Nevis',
    MF: 'São Martinho',
    ST: 'São Tomé e Príncipe',
    VC: 'São Vicente e Granadinas',
    SN: 'Senegal',
    SL: 'Serra Leoa',
    RS: 'Sérvia',
    SC: 'Seychelles',
    SX: 'Sint Maarten',
    SY: 'Síria',
    SO: 'Somália',
    LK: 'Sri Lanka',
    SZ: 'Suazilândia',
    SD: 'Sudão',
    SS: 'Sudão do Sul',
    SE: 'Suécia',
    CH: 'Suíça',
    SR: 'Suriname',
    SJ: 'Svalbard e Jan Mayen',
    TH: 'Tailândia',
    TW: 'Taiwan',
    TJ: 'Tajiquistão',
    TZ: 'Tanzânia',
    IO: 'Território Britânico do Oceano Índico',
    TF: 'Territórios Franceses do Sul',
    PS: 'Territórios palestinos',
    TL: 'Timor-Leste',
    TG: 'Togo',
    TK: 'Tokelau',
    TO: 'Tonga',
    TT: 'Trinidad e Tobago',
    TN: 'Tunísia',
    TM: 'Turcomenistão',
    TR: 'Turquia',
    TV: 'Tuvalu',
    UA: 'Ucrânia',
    UG: 'Uganda',
    UY: 'Uruguai',
    UZ: 'Uzbequistão',
    VU: 'Vanuatu',
    VE: 'Venezuela',
    VN: 'Vietnã',
    WF: 'Wallis e Futuna',
    ZM: 'Zâmbia',
    ZW: 'Zimbábue',
    XK: 'Kosovo',
  },
}

export type Locale = 'en' | 'fr' | 'de' | 'it' | 'es' | 'nl' | 'pt'

export const countriesData = new Map<Locale, CountryData>([
  ['fr', fr],
  ['en', en],
  ['it', it],
  ['es', es],
  ['nl', nl],
  ['de', de],
  ['pt', pt],
])
