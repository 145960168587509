export enum CacheKeys {
  Eligibility = 'eligibility',
  FeePlans = 'feePlans',
  Me = 'me',
  PaymentPoll = 'paymentPoll',
  Geocoding = 'geocoding',
  AlmaCities = 'almaCities',
  LocaleChange = 'localeChange',
  PaymentCancel = 'paymentCancel',
  PaymentCreate = 'paymentCreate',
  PaymentUpdate = 'paymentUpdate',
  SimulatedPayment = 'simulatedPayment',
  Logout = 'logout',
  MerchantSelect = 'merchantSelect',
  POSVersion = 'posVersion',
  Context = 'context',
}
