import React, { forwardRef, useMemo } from 'react'

import { DropdownField } from '@alma/react-components'
import { DropdownFieldProps } from '@alma/react-components/dist/types/src/forms/Dropdown/DropdownField'
import { useIntl } from 'react-intl'

import {
  countriesData,
  Locale,
} from '@/components/Forms/PaymentCreate/CustomerInfoForm/CountrySelector/data'

interface Props {
  locale: Locale
}

interface CountryDataWithGroup {
  group?: string
  label: string
  value: string
}

const CountrySelector = forwardRef<HTMLSelectElement, Omit<DropdownFieldProps & Props, 'options'>>(
  ({ id, name, value, disabled, onChange, className, placeholder, error, locale, label }, ref) => {
    const intl = useIntl()
    const options = useMemo(() => {
      const data = countriesData.get(locale || 'en')

      if (!data) {
        return []
      }

      const countryOptions = Object.keys(data.countries)
        .map((country) => ({
          value: country,
          label: data.countries[country],
        }))
        .sort((optionA, optionB) => optionA.label.localeCompare(optionB.label))

      const countryTopOfTheList: CountryDataWithGroup[] = countryOptions
        .filter((option) => countriesData.has(option.value.toLowerCase() as Locale))
        .map((option) => ({
          ...option,
          group: intl.formatMessage({
            id: 'country-selector.most-searched',
            defaultMessage: 'Most searched countries',
            description: 'Separator label in the list of countries',
          }),
        }))

      const newCountryOptions = countryOptions
        .filter((option) => !countryTopOfTheList.includes(option))
        .map((option) => ({
          ...option,
          group: intl.formatMessage({
            id: 'country-selector.all',
            defaultMessage: 'All countries',
            description: 'Separator label in the list of countries',
          }),
        }))

      if (countryTopOfTheList.length > 0) {
        return [...countryTopOfTheList, ...newCountryOptions]
      }

      return countryOptions
    }, [locale, intl])

    function getValueFromCustomer() {
      if (!value) {
        return ''
      }

      return (
        options.find((option) => {
          if (
            option.label.toLowerCase() === value.toLowerCase() ||
            option.value.toLowerCase() === value.toLowerCase()
          ) {
            return option.value
          }
          return null
        })?.value ?? value
      )
    }

    return (
      <DropdownField
        data-testid="country-selector"
        id={id}
        name={name}
        label={label}
        value={getValueFromCustomer()}
        disabled={disabled}
        onChange={onChange}
        className={className}
        options={options}
        placeholder={placeholder}
        error={error}
        ref={ref}
      />
    )
  }
)
CountrySelector.displayName = 'CountrySelector'

export default CountrySelector
