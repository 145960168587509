import { isValid, parse, differenceInYears } from 'date-fns'
import { IntlShape } from 'react-intl'

function dateFieldValidation(value: string, intl: IntlShape) {
  if (!isValid(parse(value, 'dd/MM/yyyy', new Date()))) {
    return intl.formatMessage({
      id: 'personal-information-form.birth-date-format-error',
      defaultMessage: 'The date must be in the DD/MM/YYYY format.',
      description: 'Error message displayed when the date format is incorrect.',
    })
  }
  const date = parse(value, 'dd/MM/yyyy', new Date())
  const age = differenceInYears(new Date(), date)

  if (age < 18) {
    return intl.formatMessage({
      id: 'personal-information-form.birth-date-year-error-too-young',
      defaultMessage: 'You must be 18 years old or older',
      description: 'Error message displayed when the user is younger than 18 years old.',
    })
  }
  if (age >= 120) {
    return intl.formatMessage({
      id: 'personal-information-form.birth-date-year-error-too-old',
      defaultMessage: 'The provided date seems incorrect, please verify it',
      description: 'Error message displayed when the user is 120 years old or older.',
    })
  }
  return undefined
}

export { dateFieldValidation }
