import { ApiPayment, Locale, PaymentOrigin } from '@/types'
import { AxiosInstance } from 'axios'

export type PostBillingAddress = Partial<{
  country: string
  city: string
  phone: string
  county_sublocality: string
  last_name: string
  state_province: string
  postal_code: string
  first_name: string
  line2: string
  email: string
  line1: string
}>

export type PostPayment = {
  payment: {
    deferred_months: number
    purchase_amount: number
    deferred_days: number
    customer_cancel_url: string | null
    origin: PaymentOrigin
    return_url: string | null
    billing_address: PostBillingAddress | undefined
    installments_count: number | null
    locale: Locale
    custom_data: Record<string, string | unknown>
    customer_fee_variable?: number
    fail_fast?: boolean
    capture_method?: string
  }
  customer: {
    addresses: PostBillingAddress[] | undefined
    phone: string
    last_name: string | undefined
    first_name: string | undefined
    email: string | undefined
    birth_date?: string
    birth_place?: {
      city_name: string
      country_code: string
      department_code?: string
      insee_code?: string
    }
  }
  order: { merchant_reference: string; comment: string }
}

export const postPaymentGenerator = (client: AxiosInstance) => async (payload: PostPayment) => {
  const { data } = await client.post<ApiPayment>(`v1/payments`, payload)
  return data
}
