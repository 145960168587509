import s from '@/components/Installments/InstallmentsTotal/InstallmentsTotal.module.css'
import React, { FC } from 'react'
import { FormattedMessage, FormattedNumber } from 'react-intl'
import { useMe } from '@/components/AuthenticationManager'

type Props = {
  feesInEuros: number
  offerFees: boolean
}

export const InstallmentsTotalFees: FC<Props> = ({ feesInEuros, offerFees }) => {
  const me = useMe()

  return (
    <div className={s.fees}>
      <div>
        {offerFees ? (
          <FormattedMessage
            id="installments.total-fees-offered"
            defaultMessage="{name} covers the payment fees for you 🎁"
            description="This is the line  displayed in the installment plan summary in the payment form, where the seller can see the message that the customer will not have to pay any fees"
            values={{ name: me.merchant.name }}
          />
        ) : (
          <FormattedMessage
            id="installments.total-fees"
            defaultMessage="Fees"
            description="This is the line  displayed in the installment plan summary in the payment form, where the seller can see the total amount of fees the customer will have to pay if the merchant decided to do some fee sharing"
          />
        )}
      </div>
      {!offerFees && (
        <div className={s.feesNumbers}>
          <FormattedNumber value={feesInEuros} style="currency" currency="EUR" />
        </div>
      )}
    </div>
  )
}
